var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "position-relative" },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            permanent: "",
            color: "#273444",
            width: "70",
            floating: "",
            "hide-overlay": "",
            "disable-resize-watcher": "",
            "disable-route-watcher": "",
            touchless: "",
            fixed: "",
            app: "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "logoTop d-flex justify-center align-center" },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _c("img", { attrs: { src: _vm.imgLogo } }),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "sidebarMenuList" },
            [
              _c(
                "v-list",
                {
                  staticClass: "px-0 pt-0 pb-0",
                  attrs: { dense: "", nav: "" },
                },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "v-list-item",
                    {
                      key: item.title,
                      staticClass: "itemMenuSidebar",
                      attrs: {
                        link: "",
                        to: item.route,
                        disabled: item.disabled,
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        {
                          staticClass:
                            "\n              iconMenuSidebard-flex\n              flex-column\n              justify-content-center\n              pt-5\n            ",
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(item.icon)),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "font10pt mt-2 text-center menutext",
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          ),
                        ],
                        1
                      ),
                      item.submenu
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "open-on-hover": "",
                                    attach: "aside",
                                    "allow-overflow": "",
                                    "offset-x": "",
                                    "content-class": "floatMenu elevation-0",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "submenu-dashboard position-absolute",
                                                  },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    { staticClass: "sublink" },
                                    _vm._l(
                                      item.submenu,
                                      function (item, index) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            attrs: { link: "", to: item.link },
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }